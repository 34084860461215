import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import { getPageDataSelector } from 'common/selectors/page';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

import { setToObject } from 'common/utils';
import { fetchBanners } from 'common/actions/PageActions';

import { BANNERS_TYPE_INFOBOX } from 'common/constants/SiteConstants';

const InfoBoxVariant = loadable(props => import(`./${props.blockVariant}`));

class InfoBox extends PureComponent {
  componentDidMount() {
    const { fetchInfoBoxBanners } = this.props;
    fetchInfoBoxBanners();
  }
  render() {
    const {
      blockSettings = 'InfoBoxVariant1',
      data,
      ...rest
    } = this.props;
    if (_.isEmpty(data)) {
      return null;
    }
    return (
      <InfoBoxVariant
        blockVariant={_.get(blockSettings, 'blockVariant')}
        data={data}
        {...rest}
      />
    );
  }
};

const blockSettings = {};
const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'InfoBoxVariant'
  );
  return (state, props) => {
    const pageData = getPageData(state, props);
    return {
      data: _.get(pageData, 'infoBox', null),
      blockSettings: setToObject(blockSettings, {
        blockVariant: reactiveTemplateConfig(state, props)
      })
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchInfoBoxBanners: () => {
      dispatch(fetchBanners(BANNERS_TYPE_INFOBOX, null, 'infoBox'));
    }
  };
};

const InfoBoxConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InfoBox);

export default withTheme(InfoBoxConnected);
